<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <!-- <img :src="importedImg1" :alt="altText" class="imgItem1" /> -->
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400" >
       <h1>AVISO DE PRIVACIDAD</h1>
       <div class="title-line"></div>
       <div class="textBox" style="white-space: pre-wrap">{{text}}</div>
       
      </div>
    </div>
 
  </div>
</template>

<script>
import importedImg1 from "@/page/maybaengcompanylimitedapp/components/img/4_1.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      text:`En virtud de lo dispuesto por la Ley Federal de Protección de Datos Personales en Posesión de Particulares (LFPDPPP), su Reglamento, los Lineamientos del Aviso de Privacidad y las demás Disposiciones aplicables (en adelante denominados conjuntamente como la “Ley”), se emite el presente Aviso de Privacidad en los siguientes términos:
RESPONSABLE DE LOS DATOS PERSONALES.

Permisos

Para evaluar su cualificación y facilitar su pago más rápido de su préstamo, necesitamos algunos permisos para acceder a su teléfono: SMS de contacto y Cámara. Puede eliminar su información personal en cualquier momento comunicándose con nuestro Centro de Atención al Cliente.

1. SMS:

cargará y transferirá los datos de tus SMS desde su teléfono a servidor. Los SMS se utilizarán para la gestión de riesgos y las comprobaciones antifraude. a nosotros nunca compartirá datos con tercera parte sin su permiso. La información solo se recopilará con su consentimiento. Los datos se transferirán y cargarán a través de una conexión segura (https).

2. Imagen:

Sólo recopilaremos imagen cargada por el usuario y la utilizaremos con fines de verificación de identidad, y no compartiremos la información personal del usuario con terceros sin su consentimiento.

3. Cámara:

Los permisos de la cámara nos permiten escanear los documentos requeridos de KYC, permitiéndonos completar automáticamente los campos relevantes. Además, se utilizará para realizar su proceso KYC de video.

4. Notificaciones:

Los usuarios pueden elegir si permiten el permiso de notificaciones push o no, si eligen que sí, enviaremos notificaciones importantes a los usuarios, si eligen que no, no enviaremos ninguna notificación, pero la aplicación seguirá funcionando normalmente.

5. Ubicación:

Recopilamos información de ubicación de su dispositivo para reducir los riesgos asociados con su cuenta y aumentar la seguridad de los datos. Todas las transacciones están protegidas por encriptación SSL de 128 bits. Los datos se cargarán y almacenarán en nuestro servidor a través de una conexión segura

6. Contactos de emergencia:

Sólo recopilaremos sus contactos de emergencia para la verificación de identidad, la gestión de riesgos y las comprobaciones antifraude. Usted mismo puede elegir la forma de recopilar la información de contactos: a. Rellenar manualmente; b. Rellenar automáticamente: Seleccione 1 información de contacto de su Agenda (Nota: Este método sólo PUEDE obtener un contacto seleccionado en su Agenda, y NO PUEDE obtener toda su información de contacto). Nunca compartiremos sus datos con terceros sin su permiso. Los datos se cargarán y almacenarán en nuestro servidor a través de una conexión segura (https).
DATOS PERSONALES RECABADOS Y SOMETIDOS A TRATAMIENTO.

Conforme a lo anterior, te informamos que a nosotros obtendrá tus datos personales, ya sea de forma directa o indirecta, cuando realizas una solicitud y proporcionas tus datos personales para efectos de la prestación de los servicios de crédito que ofrece a nosotros, a través de nuestra página de Internet o aplicación nativa Android y/o IOS (“App”), vía telefónica, a través de un tercero o cualquier otro medio personal o electrónico.

Almacenamiento de datos, Respecto a toda la información recopilada, la almacenaremos en el servidor de a nosotros con un alto nivel de protección,y no se compartirá con ningún tercero.

a nosotros sujeta a tratamiento tus siguientes Datos Personales obtenidos a través de los medios antes descritos:

a) Datos de identificación

b) Datos laborales

c) Datos patrimoniales

d) Datos económicos/financieros

e) Detalles de tu compra

f) Información de tu teléfono móvil y/o Apps. Incluyendo sin limitar, la información relacionada con tu servicio celular, lo que incluye: (i) operador; (ii) pagos del servicio, servicios adicionales y/o recargas de saldo para el servicio celular o servicios adicionales; (iii) hábitos de consumo; (iv) marca y modelo del teléfono móvil; y (v) Apps.

g) Información técnica sobre uso, como la dirección de protocolo de Internet (IP), información como páginas web vistas anteriormente o posteriormente, duración de cada visita / sesión, la identidad del dispositivo de Internet ( ID) o la dirección de control de acceso a los medios.

h) Recopilamos, en su totalidad, sus datos de SMS, pero solo monitoreamos sus mensajes relativos con transacciones financieras. Para ser más específico, monitorearemos el nombre del remitente, la descripción y el monto de la transacción para realizar la valoración del riesgo crediticio. Dicha funcionalidad admite un desembolso de préstamo más breve y dinámico. No se monitorean, no se analizan,no se almacenan, ni se comparten datos personales de SMS en ningún caso.

Estos datos podrán ser utilizados, entre otros fines que conforme al apartado D siguiente se describen, para evaluar los riesgos inherentes al crédito que solicites, verificar tu identidad, evaluar y calificar tu capacidad crediticia y para fines estadísticos.

a nosotros no recaba directamente datos personales de menores de edad a través de formularios, cuestionarios, ni a través de su página web. Asimismo, te informamos que a nosotros no recaba datos sensibles para su tratamiento.

Para efectos de proporcionar datos de terceros, ya sea información para la adquisición de productos o servicios ofrecidos por socios comerciales o cualquier otro motivo, será tu entera responsabilidad notificar a los mismos sobre la transferencia de sus datos personales al Responsable. En todo caso, a nosotros tratará dicha información con base en las disposiciones de la Ley y este Aviso de Privacidad y, asimismo, en nuestra calidad de Responsable y en cumplimiento con las disposiciones de la Ley, pondremos a disposición de dichos terceros el presente Aviso de Privacidad en el primer contacto que tengamos con los mismos.

Se hace de tu conocimiento que a nosotros no trabaja con agentes, comisionistas, ni otras figuras similares, por lo que cualquier dato que proporciones a cualquier tercero que se haga pasar como tal, no se encontrará bajo la responsabilidad de a nosotros y, por lo tanto, tú como Titular de los datos, serás el único responsable de la información y datos personales que compartas y el tratamiento que se les dé bajo dichos supuestos.
OPCIONES Y MEDIOS QUE a nosotros, OFRECE A LOS TITULARES DE DATOS PERSONALES PARA LIMITAR EL USO O DIVULGACIÓN DE SUS DATOS PERSONALES.

Podrás limitar el tratamiento de tus datos personales de la siguiente forma:

I. El uso, siguiendo las instrucciones indicadas en el último párrafo del apartado C.

II. La divulgación, siguiendo las instrucciones indicadas en el apartado G.

III. Dirigiendo la solicitud correspondiente al área de Legal y Compliance para ejercer tus derechos ARCO conforme a los lineamientos establecidos en el apartado F.

IV. En el caso de los datos personales recabados conforme al último párrafo del apartado E siguiente, podrás limitar su tratamiento conforme a lo ahí descrito.

Asimismo, te recordamos que puedes acudir al Registro Público de Usuarios de la CONDUSEF (REUS) y registrarte para limitar el uso de tus datos personales para fines de publicidad y promoción por parte de cualquier Entidad Financiera.
FINALIDADES A LAS QUE SE SUJETARÁ EL TRATAMIENTO DE DATOS PERSONALES.

a nosotros hace uso de tus datos personales conforme a los siguientes fines:

a) Finalidades originarias y necesarias para la prestación del servicio.

1.Identificación del solicitante y/o cliente, según sea el caso.

2.Integración del expediente de información de nuestro cliente.

3.Gestión, control, administración y procesamiento de la solicitud de crédito y, en su caso, el otorgamiento de este.

4.Gestión, control, administración, ejecución y cumplimiento del contrato de crédito celebrado con el cliente conforme a la solicitud correspondiente.

5.Gestión, control y administración de la cobranza administrativa (a través de cualquier medio de contacto otorgado en la solicitud), extrajudicial y, en su caso, judicial derivada de los servicios proporcionados por a nosotros.

6.Investigación de cambios en perfil transaccional del cliente.

7.Cumplimiento a obligaciones de carácter fiscal o comercial.

8.Atención de consultas, dudas, aclaraciones o quejas del cliente.

9.Estadística y registro histórico de usuarios y clientes.

10.Creación, mantenimiento y entrenamiento de los modelos de riesgo predictivos de a nosotros.

11.Mantener la seguridad de la información y de la operación.

12.Protección ante casos de robo de identidad.

En caso de que no desees que se utilicen tus datos para estos fines adicionales por favor selecciona la siguiente casilla:

No deseo que mis datos sean utilizados para las finalidades secundarias descritas anteriormente. Recuerda que puedes solicitar la revocacio?n de tu consentimiento en cualquier momento ingresando nuevamente a este aviso de privacidad y seleccionando la casilla correspondiente, en caso de que nos lo hayas otorgado previamente. Dicha solicitud aplica desde el momento que selecciones la casilla. Para que tu revocación sea guardada correctamente, deberás iniciar sesión en la página de nuestra aplicación y seguir el procedimiento descrito previamente. La negativa para el uso de tus datos personales para estas finalidades no sera? motivo para negarte el servicio.
TRANSFERENCIA DE DATOS PERSONALES.

a nosotros podrá realizar la transferencia y/o remisión de datos a:
a nosotros puede transferir tus datos personales a: Razones ¿Puedes limitar o cancelar la transferencia de tus datos?
Sociedades controladoras, subsidiarias o afiliadas a a nosotros o a una sociedad matriz Resguardo, control, análisis estadístico, evaluación, mejora y diseño de nuevos productos. No
Terceros no afiliados (prestadores de servicios) Asistencia en la ejecución de los servicios relacionados con tu crédito. No
Autoridades administrativas, judiciales o gubernamentales, así como a Sociedades de Información Crediticia Por mandato judicial o administrativo o cuando una ley lo determine No
Terceros subcontratados Procesar información por cuenta de a nosotros y bajo instrucciones de este. No
Proveedores de manejo de riesgo, seguridad de la información y/o software Para la prevención de fraude. No
especializado. 
Socios comerciales Para la adquisición de productos o servicios obtenidos a través de créditos a nosotros. No
Afiliados comerciales* Fines publicitarios. Sí

Los terceros y las entidades receptoras de datos personales, asumen las mismas obligaciones y/o responsabilidades de a nosotros, de conformidad con lo descrito en el presente Aviso de Privacidad.

Te informamos que tus datos personales podra?n ser transferidos y tratados por personas distintas a a nosotros, en Me?xico o en el extranjero cuando se trate de: a) Cualquier prospecto, comprador potencial o adquirente de a nosotros o de cualesquiera de sus activos, incluyendo su cartera de cre?dito, otorgarle financiamiento, adquirir las acciones o activos de a nosotros o sus accionistas o filiales en Me?xico; b) Cualquier autoridad o auditor interno y externo cuando así lo requieran para llevar a cabo la auditoría correspondiente; c) Cualquier proveedor o prestador de servicios de cobranza, ana?lisis de riesgo crediticio y otros que a nosotros considere necesario contratar.

Para la transferencia indicada con asterisco (*) requerimos tu consentimiento, el cual nos estás otorgando al aceptar este aviso de privacidad.

En caso de que no desees que tus datos sean transferidos a afiliados comerciales, por favor selecciona la siguiente casilla:

No deseo que mis datos sean transferidos para fines publicitarios.

Recuerda que puedes solicitar la revocación de tu consentimiento en cualquier momento ingresando nuevamente a este aviso de privacidad y seleccionando la casilla anterior en caso de que nos lo hayas otorgado previamente. Dicha solicitud aplica desde el momento que selecciones la casilla. Para que tu revocación sea guardada correctamente, deberás iniciar sesión en la página de nuestra aplicación y seguir el procedimiento descrito previamente. La negativa respecto a la transferencia de tus datos personales para estas finalidades no será motivo para negarte el servicio.

REVOCACIÓN DEL CONSENTIMIENTO.

En cualquier momento, podrás revocar tu consentimiento para el tratamiento de tus datos personales, siempre y cuando, dicha revocación sea legítima y no suponga la imposibilidad de cumplir obligaciones derivadas de una relación contractual vigente entre a nosotros y tú, o no sea posible en virtud de un mandato legal o judicial.

Puedes solicitar la revocación de tu consentimiento, iniciando sesión en sitio web seleccionando las casillas deseadas. La negativa respecto al tratamiento de tus datos personales para estas finalidades y/o transferencias, no vulnerará tu relación contractual con a nosotros.

CAMBIOS AL AVISO DE PRIVACIDAD.

Cualquier actualización, modificación o cambio al presente aviso de privacidad, que se realice atendiendo a novedades legislativas y/o modificaciones en las políticas internas de a nosotros, será publicado en el sitio web.

Sin embargo, cuando el contenido del presente Aviso de Privacidad cambie sustancialmente; por ejemplo, si las finalidades para las cuales fueron recabados los datos personales cambiaran, te será informado y requeriremos nuevamente de tu consentimiento para continuar tratando tus datos personales.
POLÍTICAS DE SEGURIDAD.

a nosotros protege tus datos personales usando el estándar de la industria en materia de encriptación, de esta forma garantizamos que los datos que se envían desde la página de Internet lleguen seguros a nuestros servidores. Almacenamos y procesamos tu información manteniendo siempre medidas de seguridad orientadas a proteger tus datos personales.

En a nosotros contamos con procedimientos que dictan quién y bajo qué condiciones se puede tener acceso a los datos, dando siempre la máxima prioridad a la protección de tu privacidad. Asimismo, asumimos medidas de seguridad físicas para prevenir el acceso no autorizado a nuestros sistemas e instalaciones.
Su consentimiento.

Con la lectura y aceptación del presente Aviso de Privacidad cada vez que ingresas a tu perfil de usuario por cualquiera de los medios disponibles para ello, manifiestas expresamente que: (i) el presente Aviso de Privacidad te ha sido dado a conocer por a nosotros y (ii) has leído y entendido en su totalidad los términos y alcances expuestos, por lo que expresamente otorgas tu consentimiento para el tratamiento de tus datos personales en los términos del mismo.`,
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #F9F9F9;
}
.imgBox1
{
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 6300px;
  background-color: #FFFFFF;
  border-radius: 32px;
  margin: -140px auto 150px;
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
}

.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
h1{
  /* margin-top: 50px; */
 margin-left: 490px;
 color: #17504C;
 font-size: 40px;
/* border-bottom: 5px solid #04358A; */
display: inline-block;
}
.textBox{
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
  font-size: 18px;
  color: #333333;
}
.imgBox2{
  transform: scale(90%);
  margin: -230px auto -200px;
  /* box-shadow:0 8px 8px rgba(0, 0, 0, 0.25); */
}

.title-line{
  width: 430px;
  /* background: url(./components/img/Group1.png); */
  /* background-repeat: no-repeat; */
  /* background-position: -150px 10px; */
  /* height: 50px; */
  /* border: 1px solid red; */
  border: 5px solid #0BAF60;
  margin: 0 500px;
}
</style>