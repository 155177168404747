<!--
 * @Description: 
 * @Author: xiaoq
 * @Date: 2024-07-10 10:40:40
 * @LastEditTime: 2024-07-10 16:48:16
 * @LastEditors: xiaoq
-->
<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1Box max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
        <div class="img1Box_1_a" @click="gotoLink1"></div>
        <div class="img1Box_2_a" @click="gotoLink2"></div>
        <div class="img1Box_3_a" @click="gotoLink3"></div>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/oportunifyloan2/components/img/footer.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,

      altText: "图片",
    };
  },
  methods: {
    gotoLink1() {
      window.open("https://www.buro.gob.mx/", '_blank');
    },
    gotoLink2() {
      window.open("https://www.condusef.gob.mx/", '_blank');
    },
    gotoLink3() {
      window.open("https://www.gob.mx/cnbv", '_blank');
    },
  }
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

img {
  display: block;
}

.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1Box,
.img2Box,
.img3Box,
.img4Box,
.img5Box,
.img6Box {
  width: 100%;
  overflow: hidden;
  background-color: "";
}

.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6 {
  width: 100%;
  margin: auto;
}

.img1Box {
  position: relative;
}

.img1Box_1_a {
  position: absolute;
  top: 23%;
  left: 76%;
  height: 36px;
  width: 250px;
  cursor: pointer;
  background: transparent;
  /* border: 1px solid red; */
}

.img1Box_2_a {
  position: absolute;
  top: 36%;
  left: 76%;
  height: 36px;
  width: 110px;
  cursor: pointer;
  background: transparent;
  /* border: 1px solid red; */
}

.img1Box_3_a {
  position: absolute;
  top: 48%;
  left: 76%;
  height: 36px;
  width: 80px;
  cursor: pointer;
  background: transparent;
  /* border: 1px solid red; */
}
</style>
